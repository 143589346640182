<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card  grid-list-md>
      <ModelTitle title="New Brand" @close="close()" />
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined autofocus
              :hide-details="nameErrors.length === 0"
              dense
              :error-messages="nameErrors"
              v-model="Brand.name"
              label="Name"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BrandService from "../service.js";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  name: "create-Brand",
  props: {
    createDialog: {
      default: false
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      Brand: {
        name: null,
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    Brand: {
      name: {
        required
      }
    }
  },
  watch: {
    createDialog(val) {
      this.dialog = val;
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.Brand.name.$dirty) return errors;
      if (!this.$v.Brand.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Brand = {
        name: null,
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        this.loading = true;
        this.Brand.name = this.Brand.name.charAt(0).toUpperCase() + this.Brand.name.slice(1)
        return BrandService.create(this.Brand)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "Brand is created"
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
